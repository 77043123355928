.video-background {
  margin-top: 0px;
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
}

video {
  width: 88%;
  height: 82%;
  object-fit: cover;
  position: relative;
  top: 0;
  left: 0;
}

/* .content {
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: white;
} */

.button-overlay {
  position: absolute;
  top: 70%;
  left: 45%;
  transform: translate(-50%, -50%);
  z-index: 2;
}

.watch-now-btn {
  padding: 10px 25px 10px 25px;
  font-size: 20px;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  border: none;
  border-radius: 5px;
  background-color: #3faeee;
  color: white;
  transition: 0.15s linear;
}

.watch-now-btn:hover {
  background-color: rgb(42, 207, 105);
}

@keyframes zoomIn {
  from {
    transform: scale(0);
  }

  to {
    transform: scale(1);
  }
}

.modal-content-zoomIn {
  animation: zoomIn 0.4s ease-in-out;
}

.you-tube {
  margin-left: 22%;
  width: 60%;
  height: 390px;
}

.close-btn {
  position: absolute;
  top: 85%;
  right: 43%;
  z-index: 1002;
}

.owl_carousel{
  display: block;
}

@media only screen and (max-width: 768px) {
  .video-background {
    margin-top: -15px;
    position: relative;
    width: 100%;
    height: 100vh;
    overflow: hidden;
  }

  .watch-now-btn {
    padding: 5px 28px 5px 28px;
    font-size: 15px;
  }

  .you-tube {
    margin-top: 20%;
    margin-left: 2%;
    width: 100%;
    height: 450px;
  }

  video {
    width: 80%;
    height: 33%;
    object-fit: cover;
    position: relative;
    top: 0;
    left: 0;
  }

  .button-overlay {
    position: absolute;
    top: 27%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
  }

  .close-btn {
    position: absolute;
    top: 70%;
    right: 30%;
    z-index: 1002;
  }

  .owl_carousel{
    display: none;
  }
}