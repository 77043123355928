.float{
	position:fixed;
	width:60px;
	height:60px;
	bottom:25px;
	left:40px;
	background-color:#25d366;
	color:#FFF;
	border-radius:50px;
	text-align:center;
  font-size:30px;
	box-shadow: 2px 2px 3px #999;
  z-index:100;
}

.my-float{
	margin-top:16px;
}

.bounce {
    animation: bounce 2s infinite;
}

@keyframes bounce {
    0%,
    25%,
    50%,
    75%,
    100% {
        transform: translateY(0);
    }
    40% {
        transform: translateY(-20px);
    }
    60% {
        transform: translateY(-12px);
    }
}

